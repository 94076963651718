
body {
    background: black;
}


.masjid-name {
    padding-top: 10px;
    font-size: 0.25in;
}


.next-salah div {
    font-size: 0.4in;
    display: inline-flex;
    flex-horizontal-center: 1;
    flex-direction: row;
}

.next-salah .alarm {
    font-weight: bold;
    font-size: 50px;
}

.clock {
    position: fixed;
    right: 80px;
    top: -30px;
}

.clock .time {
    letter-spacing: 10px;
    font-size: 3in;
    display: inline-flex;
}

.clock .time .ampm {
    font-size: 0.5in;
    margin-top: 2.7in;
    width: 150px;
}

.date {
    font-size: 0.5in;
}

.date-hijri {
    font-size: 0.6in;
}

.toprow {
    height: 200px;
}

.today {
    margin-top: 80px;
    font-size: 0.50in;
}

.today .title {
    font-size: 0.50in;
    margin-top: 30px;
}

.today time {
    font-size: 0.90in;
    margin-top: 30px;
}

.today .iqama .info time {
    font-size: 80%;
    color: grey;
}

.today .current .info time {
    color: yellow;
}

.today .col {
    border: #1b1e21 7px solid;
    border-radius: 15px;
}

.today .friday {

    border-right: black 7px solid;

}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
