
.App {
    margin-top: 10px;
    text-align: center;

}

body {
    background: black;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {

    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.toprow {
    height: 200px;
}

.toprow .row {
    height: 120px;
}

.masjid-name {

}


.toprow {
    height: 180px;
}

.masjid-name {
    padding-top: 10px;
    font-size: 0.25in;
}


.timecontainer {
    font-weight: bold;
    font-size: 0.3in;
}

.timecontainer .time {
    letter-spacing: 0px;
    font-size: 3.8in;
    font-weight: bold;
    display: inline-flex;
    vertical-align: bottom;

}

.timecontainer .time .ampm {
    letter-spacing: normal;
    font-size: 15%;
    font-weight: normal;
    margin-top: 3.0in;
    margin-right: 0px;
    Width: 100px;
}


.toprow2 .timecontainer .date {
    align-content: center;
    align-self: center;
    font-size: 0.5in;
    width: 100%;
}

.timecontainer .date-hijri {
}

.middle-row {
    margin-top: 2.9in;
}

.middle-row {
    font-size: 0.3in;
}

.middle-row .title {
    font-size: 0.4in;
    color: #eeeeff;
    font-weight: bold;
}

.tomorrow .iqama {

}


.today {
    margin-top: 0.0in;
    font-size: 1in;

}

.today .title {
    font-size: 0.60in;
    margin-top: 30px;
}

.today .row {
    border-bottom: gray solid 4px;
    vertical-align: center;
}

.today .time {
    color: greenyellow;
    font-size: 1in;
}

.today .info {
    font-size: 90%;
    color: gray;
}

.today .current .info {
    font-size: 90%;
    color: white;
}

.next-salah {
    vertical-align: top;
    margin-top: 0px;
    font-size: 45px;
    display: inline-flex;
    flex-horizontal-center: 1;
    flex-direction: row;
}


.next-salah .time {
    font-size: 45px;
}


.next-salah .alarm {

    font-size: 55px;

}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
